// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Slide/Slide.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Slide/Slide.tsx");
  import.meta.hot.lastModified = "1733461055608.9692";
}
// REMIX HMR END

import React, { useState } from "react";
import { ProductCard } from "../products/ProductCard";
import clsx from "clsx";
export const Slide = ({
  products
}) => {
  _s();
  const ITEMS_PER_PAGE = 3; // Số sản phẩm hiển thị mỗi slide
  const [currentIndex, setCurrentIndex] = useState(0); // Trang hiện tại

  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE); // Tổng số trang

  const handleDotClick = index => {
    setCurrentIndex(index); // Di chuyển tới trang tương ứng
  };
  const nextSlide = () => {
    setCurrentIndex(prev => (prev + 1) % totalPages); // Vòng lặp tới trang kế
  };
  const prevSlide = () => {
    setCurrentIndex(prev => (prev - 1 + totalPages) % totalPages); // Vòng lặp về trang trước
  };
  return <div className="flex flex-col space-y-4 flex-1 overflow-hidden z-50">
      {/* Container hiển thị sản phẩm */}
      <div className="relative w-full overflow-hidden">
        <div className="flex transition-transform duration-500 ease-in-out" style={{
        transform: `translateX(-${currentIndex * 100}%)`
      }}>
          {products.map((item, index) => <div key={index} className="w-full flex-shrink-0 px-2 md:w-1/3" // Chia đều sản phẩm
        >
              <ProductCard {...item} />
            </div>)}
        </div>
      </div>

      {/* Chấm tròn điều khiển */}
      <div className="flex justify-center space-x-2 mt-4">
        {Array.from({
        length: totalPages
      }).map((_, index) => <div key={index} onClick={() => handleDotClick(index)} className={clsx("w-3 h-3 rounded-full cursor-pointer transition-all", currentIndex === index ? "bg-gray-800" : "bg-gray-400 hover:bg-gray-600")}></div>)}
      </div>
    </div>;
};
_s(Slide, "tusBbsahUVevXfyh6oH5R6YDC9Q=");
_c = Slide;
var _c;
$RefreshReg$(_c, "Slide");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;